@media screen and (min-width: 1200px) and (max-width: 1299px) {}

/* ipad pro */
@media screen and (min-width: 992px) and (max-width: 1199px) {
     
        
        .both-date-main div:first-child {
             padding: 0; 
             min-width: initial;
        }
}

/* phone and tablets */
.admin-login-right-main {
      width: 100% !important;
      max-width: 100% !important;
      flex: 1 !important;
  }
@media screen and (max-width: 991px) {
      .progress-bar-content {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    .admin-login-left-main{
        display: none !important;
    }
    .admin-login-main-flex {
        display: flex;
        height: 100vh !important;
        justify-content: center;
        align-items: center;
        flex-direction: unset;
    }
      /* .admin-login-main-flex {
            flex-direction: column;
            height: auto;
      } */

      .admin-login-left-main,
      .admin-login-right-main {
            width: 100% ;
            max-width: 100%;
            flex: 0 0 100%;
      }

      .admin-login-box {
            padding: 50px 15px 100px 15px;
      }

      .login-left-bg {
            height: 50vh;
            background-size: cover;
      }

      .user-list-flex {
            align-items: flex-start;
            margin-bottom: 24px;
            flex-direction: column;
      }


}

/* tablets */
@media screen and (min-width: 768px) and (max-width: 991px) {
      .both-date-main div:first-child {
          
            min-width: initial;
       }
      .table-header {
            align-items: flex-start !important;
             flex-direction: column;
         }
      .dashboard-content .admin-page-title {
            font-size: 18px !important;
        }
      .progress-bar-main {
            max-width: 399px;
            width: 95% !important;
            position: relative;
        }
        .progress-bar-text {
            position: absolute;
            right: -34px;
            top: -5px;
        }
      .dashboard-content .admin-page-title {
            color: #111827;
            font-size: 17px;
      }
     
.set-ck-editor .ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items {
    flex-wrap: wrap;
    width: 400px;
}
/* .dashboard-content .user-list-page-title {
      margin-bottom: 20px;
      width: 100%;
  } */
  .user-list-flex {
      align-items: flex-start;
      margin-bottom: 24px;
      flex-direction: initial !important;

}
}

/* phone */
@media (max-width: 767px) {
      .paymentlist-table .table {
            margin: 0px;
            min-width: 732px !important;
        }
     
      .userlist-table-main2 .table {
            margin: 0px;
            min-width: 956px;
        }
      .file-upload-btn-main {
            max-width: 100%;
        }
        .upload-section {
            width: 50%;
        }
        .banner-img {
            margin-right: 0;
        }
        .exp-btn {
            margin-left: 0px;
        }
        .exp-btn2 {
            margin-left: 15px;
        }


      .dropdown-box2 { 
            margin-right: 0px;
        }
        .table-header {
           align-items: flex-start !important;
            flex-direction: column;
        }
        .view-btn-main2 {
            display: flex;
            flex-direction: column;
        }
        .res-set-date-text{
            flex-direction: column;
        }
        .inner-date .date-pick-inner {
            width: 300px;
        }
      .admin-sidebar-logo {
            margin-left: 25px !important;
      }
      .admin-sub-title-main2{
            margin-bottom: 20px;
      }
      .admin-sidebar-logo {
           
            order: 0 !important;
        }
    
      .res-edit-profile-flex{
            justify-content: space-between !important;
      }
      .upload-btn{
            /* margin: 10px 0px; */
                  margin: 10px 0px 10px 0px;   
      }
      .progress-bar-main {
            max-width: 399px;
            width: 95% !important;
            position: relative;
        }
        .progress-bar-text {
            position: absolute;
            right: -34px;
            top: -5px;
        }
      .set-ck-editor .ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items {
            flex-wrap:wrap;
        }
    .admin-login-left-main{
        display: none !important;
    }
    .admin-login-main-flex {
        display: flex;
        height: 100vh !important;
        justify-content: center;
        align-items: center;
        flex-direction: unset;
    }
      .admin-sidebar-logo-main .sidebar-close-btn {
            display: block !important;
      }

      .dashboard-containt-main {
            padding: 88px 15px 24px 15px;
      }

      .admin-dashboard-inner-box {
            max-width: 100%;
      }

      .dashboard-content .admin-page-title {
            font-size: 20px;
            line-height: 25px;
      }

      .admin-dashboard-inner-box .admin-dash-price {
            font-size: 20px;
            line-height: 25px;
      }

      .dash-icons {
            height: 35px;
            width: 35px;
      }

      /* .admin-login-main-flex {
            flex-direction: column;
            height: auto;
      } */

      .admin-login-left-main,
      .admin-login-right-main {
            width: 100%;
            max-width: 100%;
            flex: 0 0 100%;
      }

      .admin-login-box {
            padding: 50px 15px 100px 15px;
      }

      .login-left-bg {
            height: 50vh;
      }

      .add-user-input .form-control-textarea {
            min-width: 100%;
      }

      .user-btn-flex {
            margin-top: 0px;
      }

      .user-list-flex,
      .userlist-btn-flex {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
      }

      .user-list-inner-flex {
            width: 100%;
      }

      .export-btn-main .export-btn,
      .export-btn-main .export-btn:hover {
            margin-right: 0px;
            min-width: 150px;
      }

      .adduser-btn-main .adduser-btn,
      .adduser-btn-main .adduser-btn:hover {
            min-width: 150px;
      }

      .dashboard-content .user-list-page-title {
            margin-bottom: 20px !important;
            width: 100%;
        }

      .user-search-box {
            margin-bottom: 15px;
            margin-right: 0px;
      }

      .user-search-main {
            width: 100%;
      }

      .bergurmenu-icon {
            height: 20px;
            object-fit: contain;
            width: 20px;
      }

      .admin-header-icon-box {
            margin-right: 15px;
      }

      .admin-header-row .bergur-button {
            padding: 0px;
            min-width: 37px;
      }

      .dashboard-left-main {
            flex: 0 1;
            max-width: 0;
            width: 0;
      }

      .dashboard-right-main {
            margin-left: 0px;
      }

      .admin-sidebar-logo-main {
            display: flex;
            justify-content: space-between;
      }

      .close-icon {
            height: 23px;
            width: 23px;
            object-fit: contain;
            display: block !important;
      }

      .admin-sidebar-logo {
            order: initial;
            margin: initial;
      }

      .admin-header-main.active .admin-sidebar-logo {
            opacity: 1;
            display: block;
            height: 35px;
            margin-left: 10px;
      }

      .admin-header-main.admin-header-deactive .admin-sidebar-logo {
            opacity: 1;
            margin-left: 10px;
            height: 35px;
      }

      .admin-header-main {
            left: 0;
      }

      .admin-header-deactive .admin-header-logo-main {
            flex-direction: row-reverse;
      }

      .filter-main .MuiDrawer-paper {
            min-width: 300px;
            max-width: 300px;
      }

      .filter-main {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            width: 100%;
            margin-bottom: 14px;
      }
      .progress-bar-text {
            right: 0px;
            top: -23px;
      }
}