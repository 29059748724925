* {
      box-sizing: border-box;
      padding: 0px;
      margin: 0px;
}

@font-face {
      font-family: 'Poppins-Regular';
      src: url(../fonts/Poppins/Poppins-Regular.ttf);
}

@font-face {
      font-family: 'Poppins-Bold';
      src: url(../fonts/Poppins/poppins-bold.ttf);
}

@font-face {
      font-family: 'Poppins-Medium';
      src: url(../fonts/Poppins/Poppins-Medium.ttf);
}

@font-face {
      font-family: 'Poppins-SemiBold';
      src: url(../fonts/Poppins/Poppins-SemiBold.ttf);
}

.grid-main .grid-item {
      background-color: transparent;
      box-shadow: none;
      border-radius: 0px;
      padding: 0px;
}

.text-decoration-none {
      text-decoration: none !important;
}

.flex-all {
      display: flex;
      justify-content: space-between;
}

.form-group .form-control input {
      border: 1px solid #D1D5DB;
      border-radius: 4px;
      width: 100%;
      padding: 9.5px 15px;
      background-color: transparent;
}

.form-group .form-control input::placeholder {
      color: #6B7280;
      font-size: 12px;
      line-height: 20px;
      font-family: 'Poppins-Regular';
      opacity: 1;
}

.form-group .form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
      border-width: 0px;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: #3E3E3E !important;

}

.form-control-textarea:focus-visible {
      outline: none !important;
}

fieldset {
      border: 0px;
}

.page-border {
      border: 1px solid #D1D5DB;
      border-radius: 6px;
      padding: 20px;
}

.btn-main-primary .btn-primary,
.btn-main-primary .btn-primary:hover {
      background-color: #000000;
      border-radius: 4px;
      width: 100%;
      max-width: 380px;
      color: #FFFFFF;
      font-size: 14px;
      line-height: 21px;
      font-family: 'Poppins-Regular';
      height: 44px;
      text-transform: capitalize;
}

.border-btn-main .border-btn {
      border: 1px solid #D1D5DB;
      border-radius: 4px;
      background-color: #FFFFFF;
      padding: 6px 14px 5px;
      font-size: 12px;
      line-height: 18px;
      color: #6B7280;
      font-family: 'Poppins-Regular';
      text-transform: capitalize;
}

/* .border-btn-main .border-btn:hover {
      padding: 6px 14px 5px;
      font-size: 12px;
      line-height: 18px;
      min-width: 100px;
      border: 1px solid #D1D5DB;
      border-radius: 4px;
} */

.common-para {
      font-size: 14px !important;
      line-height: 21px !important;
      font-family: 'Poppins-Regular';
}

/* start admin login css */

.admin-login-main-flex {
      display: flex;
      height: 100vh;
      justify-content: center;
      align-items: center;
  }
  
  .admin-login-left-main {
      width: 100%;
      max-width: 50%;
      flex: 0 0 50%;
  }
  
  .admin-login-right-main {
      width: 100%;
      max-width: 50%;
      flex: 0 0 50%;
  }
  
  .login-left-bg {
      height: 100%;
      object-fit: cover;
      background-repeat: no-repeat;
      text-align: center;
      background-color: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  
  .admin-login-main {
      max-width: 380px;
      margin: auto;
      width: 100%;
  }
  
  .login-btn-main .login-btn {
      margin-top: 28px;
  }
  
  .checkbox-main .checkbox-lable {
      color: #6B7280;
      margin-left: 0px;
      margin-right: 0px;
  }
  
  .checkbox-lable .MuiCheckbox-root {
      padding: 0px;
      margin-right: 10px;
  }
  
  .checkbox-lable .MuiFormControlLabel-label {
      font-family: 'Poppins-Regular';
      font-size: 14px;
      line-height: 21px;
  }
  
  .admin-login-inner .admin-wel-text {
      color: #111827;
      font-size: 24px;
      line-height: 25px;
      font-family: 'Poppins-Bold';
      margin-bottom: 4px;
  }
  
  
  
  .admin-login-inner .admin-sign-para {
      margin-bottom: 32px;
      color: #6B7280;
  }
  
  .input-box .form-lable {
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0px;
      font-family: 'Poppins-Medium';
      margin-bottom: 6px;
      margin-top: 0px;
      color: #6B7280;
  }
  
  .input-box {
      margin-bottom: 28px;
  }
  
  .admin-forgot-para {
      color: #000000 !important;
  }
  
  .input-box .MuiInputBase-root {
      width: 100%;
  }
  
  .input-box .MuiInputBase-input {
      padding: 10.5px 15px;
  }
  
  .input-box .MuiInputBase-root fieldset:hover {
      border-color: transparent;
  }
  
  .checkbox-main .MuiSvgIcon-root {
    
      color: #000000;
      width: 20px;
      height: 20px;
  }
  .set-login-box{
      text-align: left;
  }
  .set-login-box-back{
      background-color: black;
      height: 100%;
  }
  .login-left-bg img{
      height: 305px;
      width: 480px;
      object-fit: contain;
  }

/* end admin login css */

/* start admin dashboard page structure css */

.dashboard-left-main {
      flex: 0 0 290px;
      max-width: 290px;
      width: 100%;
      transition: 0.5s all;
}

.dashboard-right-main {
      flex: 1;
}


.dashboard-containt-main {
      background-color: #FFFFFF;
      min-height: 100vh;
      padding: 75px 24px 0px 24px;
}

.dashboard-main {
      display: flex;
      width: 100%;
}

/* end admin dashboard page structure css */



/* start admin sidebar css */
.admin-sidebar-logo-main .sidebar-close-btn {
      display: none;
}

.admin-sidebar-icons {
      margin-right: 8px;
}

.admin-sidebar-main {
      background-color: #000000;
      height: 100%;
      left: -500px;
      overflow: auto;
      /* padding: 0px 16px; */
      position: fixed;
      top: 0;
      width: 290px;
      /* width: 267px; */
      z-index: 9999;
      border-right: 1px solid #E5E7EB;
      transition: 0.5s all;

}

.admin-sidebar-main.active {
      left: 0px;
}

.admin-sidebar-deactive {
      flex: 0 0 0px;
      max-width: 0px;
      width: 100%;
      transition: 0.5s all;
}

.admin-sidebar-logo {
      max-width: 130px;
      height: 45px;
    width: 100%;
    object-fit: inherit;
    margin: auto;
    display: flex;
    order: 2;
}

.close-icon {
      display: none;
}

.admin-sidebar-logo-main {
      padding: 10px 0px;
      /* background-color: #07415D; */
      background-color: #000000;
}

.admin-sidebar-list-main {
      padding: 16px 16px;
      /* background-color: #114A65; */
      background-color: #000000;
}

.admin-header-drop-main .drop-header-btn {
      padding: 0px;
}

.admin-sidebar-list-main .admin-sidebar-list {
      padding: 0px;
}

.admin-sidebar-list-main .admin-sidebar-listitem {
      padding: 0px;
}

.admin-sidebar-list-main .admin-sidebar-link {
      font-size: 14px;
      line-height: 21px;
      font-family: 'Poppins-Regular';
      color: #FFFFFF;
      display: flex;
      padding: 10px 12px 10px;
      margin-bottom: 10px;
      width: 100%;
      text-decoration: none;
}

.admin-sidebar-list-main .admin-sidebar-link.active {
      color: #FFFFFF;
      /* background-color: #1B698E; */
      /* background-color: #687074; */
      background-color: #02b738;
      border-radius: 4px;
}

.last-col{
      text-align: end !important;
}

.admin-sidebar-main::-webkit-scrollbar {
      width: 2px;
}

/* / / Track / / */
.admin-sidebar-main::-webkit-scrollbar-track {
      background: #f1f1f1;
}

/* / / Handle / / */
.admin-sidebar-main::-webkit-scrollbar-thumb {
      background: #888;
}

/* / / Handle on hover / / */
.admin-sidebar-main::-webkit-scrollbar-thumb:hover {
      background: #555;
}

/* end admin sidebar css */


/* start admin header css */


.admin-header-main {
      background-color: #FFFFFF;
      position: fixed;
      top: 0px;
      left: 290px;
      /* left: 267px; */
      right: 0px;
      z-index: 9;
      padding: 15px 20px 13px 20px;
      border-bottom: 1px solid #E5E7EB;
      transition: 0.5s all;
}

.admin-header-deactive {
      left: 0px;
}

.admin-header-main.active .admin-sidebar-logo {
      opacity: 0;
      display: none;
}

.admin-header-logo-main .admin-bergur-button {
      padding: 0px;
      min-width: 30px;
}

.admin-header-main.admin-header-deactive .admin-sidebar-logo {
      opacity: 1;
      transition: 0.5s all;
      margin-left: 10px;
      height: 35px;
}

.admin-header-logo-main {
      display: flex;
}

.admin-header-left {
      display: flex;
      justify-content: space-between;
      align-items: center;
}

.admin-header-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
}

.admin-header-icon-box {
      margin-right: 23px;
}

.admin-header-icon {
      height: 20px;
      width: 20px;
      object-fit: contain;
}

.admin-header-profile-icon {
      height: 36px;
      width: 36px;
      object-fit: cover;
      border-radius: 50%;
}

/* end admin header css */


/* start admin dashboard page */

.dashboard-content .admin-page-title {
      color: #111827;
      font-size: 26px;
      line-height: 39px;
      font-family: 'Poppins-SemiBold';
      margin-bottom: 20px;
}
.align-items{
      float: right;
}
.admin-dashboard-inner-box {
      border: 1px solid #D1D5DB;
      max-width: 100%;
      width: 100%;
      height: 90px;
      border-radius: 6px;
      padding: 15px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px;
}



.admin-dashboard-inner-box .admin-dash-text {
      color: #6B7280;
      font-size: 12px;
      line-height: 18px;
      font-family: 'Poppins-Regular';
}

.admin-dashboard-inner-box .admin-dash-price {
      color: #111827;
      font-size: 30px;
      line-height: 46px;
      font-family: 'Poppins-Medium';
}

.dash-icons {
      height: 50px;
      width: 50px;
      object-fit: contain;
}


.page-table-main .table-container {
      padding-bottom: 7px;
      box-shadow: none;
      background-color: transparent;
}

.page-table-main .table {
      margin: 0px 19px;
}

.page-table-main .table-th {
      font-size: 12px;
      line-height: 18px;
      color: #111827;
      font-family: 'Poppins-Medium';
      padding: 10px 10px 10px 0px;
      border-bottom: 1px solid #D1D5DB;
}

.page-table-main .table .table-td:first-child {
      color: #111827;
}

.page-table-main .table-td {
      font-size: 12px;
      line-height: 18px;
      color: #6B7280;
      font-family: 'Poppins-Regular';
      padding: 10px 10px 10px 0px;
      border-bottom: 1px solid #D1D5DB;
}

.page-table-main .table .table-th:nth-child(1),
.page-table-main .table .table-td:nth-child(1) {
      width: 10%;
}

.page-table-main .table .table-th:nth-child(2),
.page-table-main .table .table-td:nth-child(2) {
      width: 10%;
}

.page-table-main .table .table-th:nth-child(3),
.page-table-main .table .table-td:nth-child(3) {
      width: 15%;
}

.page-table-main .table .table-th:nth-child(4),
.page-table-main .table .table-td:nth-child(4) {
      width: 15%;
}

.page-table-main .table .table-th:nth-child(5),
.page-table-main .table .table-td:nth-child(5) {
      width: 10%;
}

.page-table-main .table .table-th:nth-child(7),
.page-table-main .table .table-td:nth-child(7) {
  
      text-align: center;
    
}
.admin-sub-title-main .admin-sub-title {
      color: #111827;
      font-size: 20px;
      line-height: 30px;
      font-family: 'Poppins-Medium';
}

.view-btn-main .view-order-btn {
      height: 28px;
      font-size: 12px;
      line-height: 18px;
      min-width: 100px;
}

.view-btn-main .view-order-btn:hover {
      height: 28px;
      font-size: 12px;
      line-height: 18px;
      min-width: 100px;
      background-color: #000000;
      color: #FFFFFF;
}



.admin-dashboard-table-row {
      border-bottom: 1px solid #D1D5DB;
      padding: 20px 19px;
}

.admin-dash-box {
      border-radius: 8px;
      border: 1px solid #D1D5DB;
      padding: 0px 0px 20px 0px;
}

.admin-dashboad-row {
      margin-bottom: 24px;
}

.display-row>* {
      padding: 0px 8px;
}

.display-row {
      margin-left: -8px;
      margin-right: -8px;
}


/* end admin dashboard page */

.table-lable-main {
      font-family: 'Poppins-Regular';
      font-size: 12px;
      line-height: 18px;
}

.paid-lable-dots {
      background-color: #04AB47;
}

.paid-lable {
      color: #04AB47;
}

.table-lable-dots {
      height: 7px;
      width: 7px;
      border-radius: 50%;
      margin-right: 4px;
}

.table-lable-flex {
      display: flex;
      align-items: center;
}

.failed-lable-dots {
      background-color: #E4004D;
}

.failed-lable {
      color: #E4004D;
}

.pending-lable-dots {
      background-color: #f39c12;
}

.pending-lable {
      color: #f39c12;
}

/* width */
.page-table-main .table-container::-webkit-scrollbar {
      width: 2px;
      height: 5px;
}

/* Track */
.page-table-main .table-container::-webkit-scrollbar-track {
      background: #D1D5DB;
      border-radius: 5px;
}

/* Handle */
.page-table-main .table-container::-webkit-scrollbar-thumb {
      background: #8888885c;
}

/* Handle on hover */
.page-table-main .table-container::-webkit-scrollbar-thumb:hover {
      background: #555;
}


/* start add user page */

.add-user-containt .add-basic-text {
      color: #111827;
      font-family: 'Poppins-SemiBold';
      margin: 0px 0px 4px;
}

.add-user-containt .add-section-text {
      color: #6B7280;
      margin-bottom: 20px;
}

.add-user-input .form-control input {
      /* max-width: 506px; */
      padding: 5.5px 15px;
      /* min-width: 453px; */
}

.add-user-input {
      margin-bottom: 24px;
}

.add-user-input .form-control-textarea {
      width: 100%;
      border: 1px solid #D1D5DB;
      border-radius: 4px;
      padding: 10px;
      min-height: 100px;
      resize: none;
}

.radio-main .radiogroup .MuiButtonBase-root.Mui-checked {
      color: #000000;
}

.radio-main .MuiFormControlLabel-label {
      color: #6B7280;
      font-size: 14px;
      line-height: 21px;
      font-family: 'Poppins-Regular';
}

.user-btn-flex {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 60px;
}

/* end add user page */


/*  start dropdown css  */

.dropdown-box .form-control {
      width: 100%;
      position: relative;
}

.dropdown-box .form-control .dropdown-select {
      border: 1px solid #D1D5DB;
}

/* .dropdown-box .form-control svg{
      display: none;
} */
.dropdown-select .MuiSelect-select {
      padding: 6px 15px;
      font-size: 14px;
      line-height: 24px;
      color: #23262F;
      font-family: 'Poppins-Medium';
      display: flex;
}

.form-control fieldset {
      border: 1px solid #E6E6E6;
      border-radius: 2px;
}

.grey-down {
      position: absolute;
      top: 17.92px;
      right: 21.92px;
      height: 10px;
      width: 10px;
      object-fit: contain;
}

/* / end dropdown css / */

/* start admin add user page */


.discard-btn-main .discard-user-btn,
.discard-btn-main .discard-user-btn:hover {
      min-width: 83px;
      margin-right: 10px;
      height: 36px;
}

.user-save-icon {
      margin-right: 8px;
      object-fit: contain;
}

.save-btn-main .save-user-btn,
.save-btn-main .save-user-btn:hover {
      min-width: 87px;
      background-color: #000000;
      color: #FFFFFF;
      height: 36px;
}

.admin-tabs-main .admin-tab {
      font-size: 14px;
      line-height: 21px;
      font-family: 'Poppins-Medium';
      color: #111827;
      text-transform: capitalize;
      padding: 10px 15px;
}

.admin-tabs-main .admin-tab.Mui-selected {
      color: #114A65;
}

.admin-tabs-main .MuiTabs-indicator {
      background-color: #000000;
      height: 2px;
}

.admin-tabpanel .edit-highlight-text {
      color: #111827;
      font-family: 'Poppins-Medium';
      margin-bottom: 4px;
}

.admin-tabpanel .edit-para-text {
      margin-bottom: 24px;
      color: #6B7280;
}

.admin-tabpanel>.MuiBox-root {
      padding: 24px 0px;
}

.user-cards{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      
}
.win-lose-main {
      /* min-width: 300px;
      max-width: 300px;     */
      min-width: 560px;
      max-width: 560px;
      padding: 10px 10px;
      box-shadow: 0 0 10px 0px #0000004d;
      border-radius: 4px;
      margin: 0 20px 0 0;
      min-height: 214px;
}

.card-total {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.card-total p{
      margin: 3px 0;
      font-family: 'Poppins-Regular';
      font-size: 14px;
}
.card-main-head {
      font-size: 20px;
      font-family: 'Poppins-Medium';
      border-bottom: 1px solid #bfbfbf;
  }
  .card-main-body {
      padding-top: 12px;
  }

  .all-user-data {
      max-width: 100%;
      min-width: 560px;
      margin-top: 20px;
      padding: 10px;
      box-shadow: 0 0 10px 0px #0000004d;
      border-radius: 4px;
  }
  .user-table-card .table{
min-width: 450px !important;
  }
  .user-table-card{
      padding: 10px;
      max-height: 360px;
      overflow: auto;
  }
  .userdata-btn-card{
      justify-content: flex-end;
  }
  .user-table-card table tbody tr .table-td:first-child{
      word-break: break-word;
  }
  .exp-btn{
      margin-left: 15px;
  }
  .exp-btn button{
      min-width: 145px !important;
  }
  .call-req-list-search .form-group{
display: flex;
  }
  .call-userlist-btn-flex{
      justify-content: end !important;
      width: 50%;
}
/* end admin add user page */


/* start admin edit user page */


.edit-profile-flex {
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;
      /* justify-content: space-around;
      width: 50%; */
}
.upload-section{
      text-align: center;
}
.edit-profile-flex div label span {
      box-shadow: none;
}

/* end admin edit user page */

/* start file upload img css */

.file-upload-btn-main .file-upload-btn,
.file-upload-btn-main .file-upload-btn:hover {
      background-color: #FFFFFF;
      border-radius: 50%;
      height: 17px;
      width: 17px;
      min-width: 17px;
      padding: 0px;
      position: absolute;
      right: 0px;
      bottom: 13px;
      /* box-shadow: 0px 3px 6px #00000029; */
}

.file-upload-btn-main {
      position: relative;
      max-width: max-content;
}
.upload-btn{
      margin: 10px 0px;
            /* margin: 10px 10px 10px 0px;    */
}
.banner-img{
      margin-right: 100px;
}

.upload-profile-img {
      height: 100px;
      width: 100px;
      object-fit: cover;
}

.upload-icon-img {
      height: 10px;
      width: 10px;
      object-fit: contain;
}

/* end file upload img css */



/* start switch css */

.switch-main {
      /* margin-left: 20px; */
      margin-left: 10px;
}

.switch-lable .MuiTypography-root {
      font-size: 14px;
      line-height: 21px;
      color: #6B7280;
      font-family: 'Poppins-Regular';
}

/* end switch css */

/* start chnage password page css */

.change-pass-main {
      border: 1px solid #D1D5DB;
      border-radius: 6px;
      padding: 20px;
      max-width: 514px;
      width: 100%;
}

.change-pass-input-box .form-group .form-control input {
      border: 1px solid #D1D5DB;
      border-radius: 4px;
      width: 100%;
      padding: 5.5px 15px;
      background-color: transparent;
}

.change-pass-flex {
      margin-bottom: 20px;
}

/* end chnage password page css */


/* start user list page */

.userdata-btn-flex {
      display: flex;
}

.userdata-btn-flex button {
      min-width: 30px;
      padding: 3px 8px;
}

.userlist-data {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
}

.userlist-img {
      margin-right: 15px;
      height: 25px;
      width: 25px;
      object-fit: cover;

}

.userlist-table-main .table {
      margin: 0px;
}

.userlist-table-main .table .table-th {
      padding: 10px 10px 10px 20px;
}

.userlist-table-main .table .table-td {
      padding: 10px 10px 10px 20px;
}

.userlist-table-main .table .table-th:first-child {
      text-align: left;
}

.userlist-table-main .table .table-th:nth-child(1),
.userlist-table-main .table .table-td:nth-child(1) {
      width: 10%;
}

.userlist-table-main .table .table-th:nth-child(2),
.userlist-table-main .table .table-td:nth-child(2) {
      width: 29%;
}

.userlist-table-main .table .table-th:nth-child(3),
.userlist-table-main .table .table-td:nth-child(3) {
      width: 15%;
}

.userlist-table-main .table .table-th:nth-child(4),
.userlist-table-main .table .table-td:nth-child(4) {
      width: 15%;
}

.userlist-table-main .table .table-th:nth-child(5),
.userlist-table-main .table .table-td:nth-child(5) {
      width: 11%;
}

.userlist-table-main .table .table-th:nth-child(6),
.userlist-table-main .table .table-td:nth-child(6) {
      width: 10%;
}

.userlist-table-main .table .table-th:nth-child(7),
.userlist-table-main .table .table-td:nth-child(7) {
      width: 10%;
}


.down-icon,
.plus-icon {
      width: 15px;
      height: 15px;
      object-fit: contain;
      margin-right: 9px;
}

.export-btn-main .export-btn,
.export-btn-main .export-btn:hover {
      margin-right: 10px;
      height: 36px;
      min-width: 96px;
}

.adduser-btn-main .adduser-btn,
.adduser-btn-main .adduser-btn:hover {
      min-width: 115px;
      height: 36px;
}

.user-search-box {
      position: relative;
      /* margin-right: 10px; */
}


.user-search-box .form-control input {
      padding: 6px 15px 6px 30px;
}

.user-search-box .form-control input::placeholder {
      color: #6B7280;
      font-size: 12px;
      line-height: 20px;
      font-family: 'Poppins-Regular';
      opacity: 1;
}

.search-grey-img {
      position: absolute;
      left: 13px;
      top: 13px;
      height: 13px;
      width: 10px;
      margin-right: 8px;
}

.user-list-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
}

.userlist-btn-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
}

.user-list-flex .user-list-page-title {
      margin-bottom: 0px;
}

/* end user list page */



/* start add user modal css */

.span-text {
      color: #D1D5DB;
      margin-left: 3px;
}

.modal .modal-title {
      color: #111827;
      font-size: 20px;
      line-height: 30px;
      font-family: 'Poppins-Medium';
}

.modal-user-btn-flex {
      display: flex;
      justify-content: flex-end;
      padding-top: 3px;
}

.modal .add-user-modal-inner-main {
      padding: 24px 24px 30px 24px;
      max-width: 500px;
      width: 100%;
      border: 1px solid #707070;
      border-radius: 10px;
}

.modal-input-box {
      margin-bottom: 24px;
}

.modal-input-box .form-group .form-control input {
      padding: 5.5px 15px;
}

.modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
}

.inner-date .date-pick-inner{
      width: 200px;
}
.both-date-main div:first-child{
      padding: 0 ;
}

/* end add user modal css */


/* start delete user modal css */

.user-circle-img {
      width: 52px;
      height: 52px;
      object-fit: contain;
      margin: auto;
      display: flex;
}

.modal-delete .delete-modal-inner-main {
      padding: 40px 24px 40px 24px;
      max-width: 500px;
      width: 100%;
      border: 1px solid #707070;
      border-radius: 10px;
}

.delete-modal-inner-main .delete-modal-title {
      color: #111827;
      font-size: 28px;
      line-height: 42px;
      font-family: 'Poppins-Medium';
      text-align: center;
      margin: 20px 0px 16px;
}

.delete-modal-inner-main .delete-modal-para {
      color: #6B7280;
      margin-bottom: 26px;
      text-align: center;
      max-width: 304px;
      width: 100%;
      margin: auto;
}

.delete-modal-btn-flex {
      display: flex;
      justify-content: center;
      margin-top: 26px;
}

.delete-modal-btn-flex .modal-btn {
      border-radius: 4px;
      border: 1px solid #D1D5DB;
      max-width: 100px;
      width: 100%;
      padding: 8px;
      text-transform: capitalize;
}

.delete-modal-btn-flex .modal-cancel-btn {
      color: #6B7280;
      margin-right: 20px;
}

.delete-modal-btn-flex .modal-delete-btn,
.delete-modal-btn-flex .modal-delete-btn:hover {
      color: #FFFFFF;
      background-color: #E4004D;
}

.MuiBackdrop-root-MuiModal-backdrop {
      background-color: rgb(17 24 39 / 60%);
}

/* end delete user modal css */


/* start header profile dropdown  */

.flex-drop-main .admin-header-drop {
      font-size: 12px;
      font-family: 'Poppins-Regular';
      line-height: 18px;
      color: #6B7280;
      text-align: start;
      margin-left: 12px;
      text-transform: capitalize;
}

.flex-drop-main .header-drop-title {
      font-family: 'Poppins-Bold';
}

.flex-drop-main {
      display: flex;
      align-items: center;
}

.drop-header {
      height: 15px;
      width: 15px;
      margin-right: 6px;
}

.drop-header-menu .MuiPaper-rounded {
      box-shadow: 10px 10px 20px #0000001A;
      border: 1px solid #E5E7EB;
      margin-top: 13px;
      border-radius: 1px;
}

.drop-header {
      height: 15px;
      width: 15px;
      margin-right: 6px;
}

.drop-header-menu .drop-header-menuitem {
      font-size: 12px;
      line-height: 14px;
      color: #6B7280;
      font-family: 'Poppins-Regular';
      border-bottom: 1px solid #E5E7EB;
      padding: 11px 16px;
}

.drop-header-menu ul {
      padding: 0px;
}

/* end header profile dropdown  */


.admin-sidebar-listitem .expandless-icon,
.admin-sidebar-listitem .expandmore-icon {
      position: absolute;
      right: 10px;
      top: 10px;
}

.admin-submenu-listitem-main .admin-sidebar-link {
      display: block;
}

.admin-sidebar-submenulist .admin-sidebar-link {
      margin-left: 10px;
}

.admin-sidebar-submenulist .admin-sidebar-listitem:first-child .admin-sidebar-link {
      margin-top: 20px;
}

/* start pagination css */

.pagination-main {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 27px;
      margin-right: 20px;
}

.pagination-main .MuiPaginationItem-root {
      height: 20px;
      min-width: 20px;
      border-radius: 4px;
      padding: 0px;
      font-size: 12px;
      line-height: 18px;
      color: #6B7280;
      font-family: 'Poppins-Regular';
      margin: 0 4px;
}

.pagination-main .MuiPaginationItem-root.Mui-selected {
      background-color: #000000;
      color: #FFFFFF;
}
/* .MuiSwitch-track-active .css-1yjjitx-MuiSwitch-track {
      background-color: #000000 !important;
} */

/* end pagination css */


/* start progrss bar css */

.progress-bar-main {
      max-width: 470px;
      width: 100%;
      position: relative;
}

.progress-bar-main .progress-bar .MuiLinearProgress-bar {
      border-radius: 8px;
      background-color: #000000;
}

.progress-bar-content {
      margin-top: 30px;
      margin-bottom: 20px;
}

.progress-bar-content .admin-page-title {
      font-size: 20px;
      line-height: 30px;
      color: #111827;
      font-family: 'Poppins-Medium';
}

.progress-bar-text {
      position: absolute;
      right: -35px;
      top: -5px;
      font-size: 14px;
      line-height: 21px;
      color: #6B7280;
      font-family: 'Poppins-Medium';
}

/* end progrss bar css */


/* start filter css */

.filter-main {
      position: relative;
}

.filter-main .MuiDrawer-paper {
      width: 100%;
      min-width: 400px;
      max-width: 400px;
}

.filter-header {
      background-color: #07415D;
      display: flex;
      justify-content: space-between;
      padding: 16px 25px;
      align-items: center;
}

img.filter-close-icon {
      height: 21px;
      width: 21px;
      object-fit: contain;
}

.filter-header .filter-title {
      font-size: 20px;
      line-height: 30px;
      color: #FFFFFF;
      font-family: 'Poppins-Regular';
}

.filter-inner-main {
      padding: 20px 25px;
      background-color: #fff;
}

.filter-btn-main {
      display: flex;
      justify-content: flex-end;
}

.filter-btn-main .filter-cancel-btn {
      min-width: 90px;
      height: 36px;
      margin-right: 20px;
}

.filter-btn-main .filter-btn,
.filter-btn-main .filter-btn:hover {
      max-width: 90px;
      height: 36px;
}

.filter-footer {
      border-top: 1px solid #D1D5DB;
      padding: 15px 25px;
      position: absolute;
      bottom: 0px;
      width: 100%;
      left: 0px;
      right: 0px;
}

.filter-checkbox-main .checkbox-lable {
      margin-right: 20px;
}

/* end filter css */

/* start otp page */

.otp-input-box .form-group .form-control input {
      max-width: 70px;
      text-align: center;
      font-size: 20px;
      line-height: 22px;
      font-family: 'Poppins-Regular';
}

.otp-input-box .form-group .MuiFormControl-root {
      max-width: 70px;
}

.otp-input-box .form-group .form-control input::placeholder {
      font-size: 20px;
      line-height: 22px;
      font-family: 'Poppins-Regular';
}

.otp-flex-main {
      display: flex;
      align-items: center;
      justify-content: space-between;
}

.otp-input-box {
      margin-right: 15px;
      margin-bottom: 10px;
}

.otp-flex-main .otp-input-box:last-child {
      margin-right: 0px;
}

/* end otp page */

.errorshow{
      color:#d32f2f; 
      font-family: "Roboto","Helvetica","Arial",sans-serif;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.66;
      letter-spacing: 0.03333em;
      text-align: left;
      margin-top: 3px;
      margin-right: 14px;
      margin-bottom: 0;
      margin-left: 14px;
  }
  .userdata-btn-flex2 .MuiButton-textSizeMedium:hover {
background-color: #008000;
  }
  .admin-login-main2 {
      max-width: 420px !important;
      margin: auto;
      width: 100%;
  }
  .input-box2 {
      margin-bottom: 15px !important;
  }
  .align-items2{
display: flex;
  }
  .set-reg-box{
      margin-right: 10px;
  }
  .reg-btn-main{
      text-align: center !important;
  }
  .userlist-data-check-box {
      flex-direction: row-reverse;
  }
  .userlist-table-main.gamewisebet-table .table .table-th:nth-child(1), .userlist-table-main.gamewisebet-table  .table .table-td:nth-child(1) {
      width: 10% !important;
    
  }
  .game-rule-table.userlist-table-main.gamewisebet-table .table .table-th:nth-child(1), .game-rule-table.userlist-table-main.gamewisebet-table .table .table-td:nth-child(1) {
      width: 10% !important;
  }
  .game-rule-table.userlist-table-main.gamewisebet-table  .table .table-th:nth-child(2), .game-rule-table.userlist-table-main.gamewisebet-table  .table .table-td:nth-child(2) {
      width: 25% !important;
    text-align: left !important;
  } 
  .game-rule-table.userlist-table-main.gamewisebet-table  .table .table-th:nth-child(3), .game-rule-table.userlist-table-main.gamewisebet-table  .table .table-td:nth-child(3) {
      width: 0% !important;
  } 
  
  .game-wise-bet-set.userlist-table-main.gamewisebet-table .table .table-th:nth-child(2), .game-wise-bet-set.userlist-table-main.gamewisebet-table .table .table-td:nth-child(2) {
      width: 50% !important;
      text-align: center;
  }
  .game-wise-bet-set.userlist-table-main.gamewisebet-table .table .table-th:nth-child(3), .game-wise-bet-set.userlist-table-main.gamewisebet-table .table .table-td:nth-child(3) {
      width: 32% !important;

  }

  .userlist-table-main.gamewisebet-table  .table .table-th:nth-child(2), .userlist-table-main.gamewisebet-table  .table .table-td:nth-child(2) {
      width: 80% !important;
      text-align: center ;
  }
  .userlist-table-main.gamewisebet-table  .table .table-td:nth-child(2) {
      width: 80% !important;
  }
  .userlist-table-main.gamewisebet-table  .table .table-td.gamerules-description:nth-child(2) {
      width: 80% !important;
      text-align: justify ;
  }

  .userlist-table-main.gamewisebet-table  .table .table-th:nth-child(3), .userlist-table-main.gamewisebet-table  .table .table-td:nth-child(3) {
      width: 10% !important;
    
  }
  .checkbox-main2 .checkbox-lable {
      color: #6B7280;
      margin-left: 0px;
      margin-right: 10px;
  }
  betlist-table
  .userlist-table-main.betlist-table .table .table-th:nth-child(1), .userlist-table-main.betlist-table  .table .table-td:nth-child(1) {
      width: 10% !important;
    
  }
  .userlist-table-main.betlist-table  .table .table-th:nth-child(2), .userlist-table-main.betlist-table  .table .table-td:nth-child(2) {
      width: 80% !important;
      text-align: center !important;
  }
  .userlist-table-main.betlist-table  .table .table-th:nth-child(3), .userlist-table-main.betlist-table  .table .table-td:nth-child(3) {
      width: 10% !important;
    
  }
  .set-check-box-game{
      display: flex;
  }
  
.resend-btn{
      background-color: black !important;
    color: white !important;
    width: 100%;
    max-width: 80px;
    height: 40px;
}
.resend-btn-box{
      align-items: center;
      padding: 10px 0px;  
      font-size: 14px !important;
      line-height: 21px !important;
      font-family: 'Poppins-Regular';
      color: #6B7280; 
       
}
.otp-flex-main2{
      margin-bottom: 10px;
}

.abc{
      position: relative;
      
}

.chat-body{
      width: 100%;
      position: absolute;
      bottom: 0;
      margin: 10px

}
.chat-input div {
      margin-right:10px;
      border-radius: 5px;
      background-color: white;

} 
.flex-item button{
      border-radius: 5px;
}
.chat-input{
      position: sticky;
      bottom: 0;
      flex: 1;
}
.flex-item{
      display: flex;
      padding: 5px;
      justify-content: space-between;
}

  /* Chat Css */

/*   
.chatbox {
      padding: 40px 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
     .container .abc{
      height: 100vh;
       background: #687074;
    } 
    .chatbox .chat {
      display: flex;
      gap: 15px;
    }
    .chatbox img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      object-fit: cover;
      border: solid 2px white;
    }
    .chatbox .msg {
      background: #ffffff;
      max-width: 80%;
      padding: 20px;
      border-radius: 10px;
      position: relative;
      font-size: 13px;
      font-family: sans-serif;
    }
    .msg ion-icon {
      font-size: 20px;
      color: #3a384c;
      position: absolute;
      top: 10px;
    }
    .chatbox .chat:nth-child(even) {
      flex-direction: row-reverse;
    }
    .chatbox .chat:nth-child(odd) ion-icon {
      left: -14px;
    }
    .chatbox .chat:nth-child(even) ion-icon {
      right: -14px;
      transform: rotate(180deg);
    }
     */


.container2 {
    width: 100%;
    max-width: 450px;
    background: #282639;
    margin: 0 auto;
    margin-top: 50px;
    border-radius: 10px;
    position: relative;
    box-shadow: #292739 0 10px 20px;
    padding: 0px;
    
  }

  .chat-header{
      
      background-color: #687074;
  }
  .chat-heading{
      display: flex;
      justify-content: flex-start;
      background-color: #ffffff;
      align-items: center;
      margin: 0 0 10px;
  }
  .chat-heading .back-button button{
      border: none;
      padding: 0;
      align-items: flex-start;
      justify-content: flex-start;
      min-width: 40px !important;
  }
  .chat-heading h6{
      font-size: 20px;
      line-height: 21px;
      font-family: 'Poppins-Regular';
  }
  
  .chatbox {
    padding: 0px 15px;
    padding-top:20px ;
    padding-bottom:48px ;
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: 520px;
    overflow: auto;
  }
  .bottom-sent-chat .MuiInputBase-colorPrimary{
      height: 45px;
      background-color: white !important;
  }
  .chatbox .chat {
    display: flex;
    gap: 15px;
  }
  .chatbox img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
    border: solid 2px white;
  }
  /* .chatbox .msg {
      background: #ffffff;
      max-width: 45%;
      padding: 10px 10px;
      border-radius: 10px;
      position: relative;
      font-size: 13px;
      line-height: 23px;
      font-family: sans-serif;
      word-break: break-word;
      min-height: 40px;
  } */
  .msg ion-icon {
    /* font-size: 20px;
    color: #3a384c; */
    position: absolute;
    top: 42px;
  }
  /* .chatbox .chat:nth-child(even) {
    flex-direction: row-reverse;
  } */
  .chatbox .chat-left-msg .right-icon  {
    left: 0px;
    top: 42px;
    transform: rotate(180deg);

  }
  .chatbox .chat-right-msg .right-icon  {
      right: -5px;
      top: 42px;
      transform: rotate(180deg);
  
    }
  /* .chatbox .chat .left-icon  {
    right: -5px;
    transform: rotate(180deg);
  } */

  
  .chatbox .chat-left-msg .right-icon{
      left: 0px;
      transform: rotate(180deg);
  }
  .chatbox .chat-right-msg .right-icon{
      right: -5px;
      transform: rotate(180deg);
  }
  /* .left:before {
    content: '';
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #ffffff;
    width: -13px;
    position: absolute;
    left: -4px;
    top: 12px;
} */
/* .chatbox .chat-left-msg .msg  .left:before{
      content: '';
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid #ffffff;
      width: -13px;
      position: absolute;
      left: 0px;
      top: 12px;
}
.chatbox .chat-right-msg .msg  .left:before{
      content: '';
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid #ffffff;
      width: -13px;
      position: absolute;
      left: -5px;
      top: 12px;
} */

.chatbox .chat-right-msg{
      flex-direction: row-reverse !important;
}
.chatbox .chat-left-msg{
      flex-direction: row !important;
}





::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  ::-webkit-scrollbar-thumb {
    background: grey;  
    border-radius: 10px;
  }
    .set-search-btn{
      width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 15px 0px;
    }
    .set-date-btn input{
      padding: 7.5px 14px !important;
    }
  .upload-btn-set{
      margin-right: 50px;
  }
 .upload-btn label{
      background-color: #000000 !important;
      text-transform: capitalize !important;
 }
.view-btn-main2{
      display: flex;
}
.dropdown-box2 {
      width: 300px !important;
    margin-right: 20px;
    /* padding-bottom: 8px !important; */
}
.dropdown-select2 .MuiSelect-select {
      min-height: 37.5px !important;
      align-items: center !important;
    margin-left: 13px !important;

}
.dropdown-select2 fieldset {
      opacity: 0;
  }
  
.box-set-game {
margin-bottom: 8px !important;
}
.box-set-date p{
      margin-bottom: 0px !important;
}
.table-header{
      align-items: center !important;
}
.table-gap{
      margin-top: 50px !important;
}

 /* chat start  */
.chatbox .chat-right-msg + .chat-right-msg img {
      display: none;
  }
  
  .chatbox .chat-right-msg + .chat-right-msg p {
      margin-right: 60px;
  }
  
  .chatbox .chat-left-msg + .chat-left-msg img {
      display: none;
  }
  
  .chatbox .chat-left-msg + .chat-left-msg p {
      margin-left: 60px;
  }
  /* .chatbox .chat-left-msg .msg  .left:before{
      content: '';
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid #ffffff;
      width: -13px;
      position: absolute;
      left: 0px;
      top: 12px;
}
.chatbox .chat-right-msg .msg  .left:before{
      content: '';
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid #ffffff;
      width: -13px;
      position: absolute;
      left: -5px;
      top: 12px;
} */


 /* .chatbox {
    padding: 0px 15px;
    padding-top:20px ;
    padding-bottom:48px ;
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: 520px;
    overflow: auto;
  } */
  .chatbox .chat-right-msg{
      flex-direction: row-reverse !important;
      margin-top: 10px;
}
.chatbox .chat-left-msg{
      flex-direction: row !important;
        margin-top: 10px;
}

  .chat.chat-right-msg + .chat-right-msg, .chat.chat-left-msg + .chat-left-msg {
    margin-top: 0;
}
 .chatbox .msg {
      background: #ffffff;
      max-width: 45%;
      padding: 10px 10px;
      border-radius: 10px;
      position: relative;
      font-size: 13px;
      line-height: 21px;
      font-family: sans-serif;
      word-break: break-word;
      min-height: 40px;
      box-shadow: 0 3px 6px #00000030;
  }
   /* chat end  */
   .userlist-table-main.paymentlist-table .table .table-th:nth-child(1), .userlist-table-main.paymentlist-table .table .table-td:nth-child(1) {
      width: 20% !important;
  }
 